<template>
    <div class="connection-page">
      <div class="text-section">
        <h1>เชื่อมต่อสำเร็จ...</h1>
        <p>กำลังกลับเข้าสู่ระบบใน 5 วินาที...</p>
      </div>
      <div class="image-section">
        <img src="/img/complete.png" alt="Success" class="success-image" />
      </div>
    </div>
</template>
  
<script>
  export default {
    created(){
      this.extractUrlParams()
    },
    mounted() {
      setTimeout(() => {
        this.redirect();
      }, 5000); 
    },
    methods: {
      extractUrlParams() {
        const urlParams = new URLSearchParams(window.location.search);

        const uid = urlParams.get('uid')
        const shopObjectId = urlParams.get('shopObjectId')
        const code = urlParams.get('code')
        const shopId = urlParams.get('shop_id')
        const app = urlParams.get('app')
        if (uid) localStorage.setItem('uid', uid)
        if (shopObjectId) localStorage.setItem('shopObjectId', shopObjectId)
        if (code) localStorage.setItem('code', code)
        if (shopId) localStorage.setItem('shop_id', shopId)
        if (app) localStorage.setItem('app', app)

        console.log("URL Parameters:", {
          uid,
          shopObjectId,
          code,
          shopId
        });
      },
      redirect() {
        const app = localStorage.getItem('app')
        if (app === 'product') {
          this.$router.push('/ecommerce/SelectProduct')
        } else if (app === 'order') {
          this.$router.push('/ecommerce/OrderList')
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .connection-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    padding: 0 50px;
    background-color: white;
  }
  
  .text-section {
    flex: 1;
    margin-left: 20%;
  }
  
  .text-section h1 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .text-section p {
    font-size: 1.25rem;
    color: #777;
  }
  
  .image-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  
  .image-section i {
    color: #4CAF50;
  }
  </style>
  